<template>
  <div class="position-relative banner-cont">
    <!-- Hero for FREE version -->
    <section class="section section-lg section-hero section-shaped">
      <!-- Background circles -->
      <div class="shape shape-style-1 shape-primary">
        <span class="span-150" />
        <span class="span-50" />
        <span class="span-50" />
        <span class="span-75" />
        <span class="span-100" />
        <span class="span-75" />
        <span class="span-50" />
        <span class="span-100" />
        <span class="span-50" />
        <span class="span-100" />
        <div class="overlay-bg" />
      </div>
      <div class="container shape-container d-flex align-items-center banner-text">
        <div class="main-search-inner">
          <h4
            class="text-white display-2"
            style="font-size:35px;font-weight:650 !important"
          >
           YOUR SOURCE FOR A NIGHT OUT NEAR YOU
          </h4>
          <!-- <h4 class="text-white">
            {{ desc }}
          </h4> -->
        </div>
        <!-- <div class="main-search-inner ml-3 text-center" style="background:rgba(34, 34, 34, 0.55)">
          <h5
            class="text-white p-3"
            style="font-size:28px"
          >Open? Mask? Vaccine?</h5>
           <h5 class="text-white text-center">Help Improve Thriggle</h5>
           <h5 class="text-white text-center"> Add Your COVID Review</h5>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-2" />
        <div class="col-lg-10 col-md-8 col-sm-8 col-xs-12">
          <a
            href="https://play.google.com/store/apps/details?id=com.thriggle.qt&hl=en&gl=US"
            target="_blank"
          >
            <img
              src="/static/images/gplay.png"
              height="50"
              alt="Play Store"
            >
          </a>
          <a
            href="https://apps.apple.com/pk/app/thriggle/id1554262256"
            target="_blank"
          >
            <img
              class="ml-3"
              src="/static/images/apple.png"
              height="50"
              alt="Apple Store"
            >
          </a>
        </div>
        <div class="col-md-6" />
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-white"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </section>
  </div>
</template>

<script>
export default {
	props: ['title', 'desc', 'bg-image-url']
};
</script>
<style scoped>
.banner-text{
	padding-top:90px !important;
}
</style>
